import { useContext, useState } from 'react';
import LanguageContext from '../../Context/Language';
import { LanguageTypes } from '../Types/LanguageTypes';
import './Contact.css'
import { ContactChinese, ContactSpanish } from './ContactText';
import LogoUbication from '../../assets/pin.png'
import LogoPhone from '../../assets/phone-call.png'
import LogoInstagram from '../../assets/instagram.png'
import LogoInstagramWhite from '../../assets/instagram-white.png'
import LogoLinkedinWhite from '../../assets/linkedin-white.png'
import LogoLinkedin from '../../assets/linkedin.png'
import LogoGmail from '../../assets/logotipo-de-gmail.png'
import LogoWechat from '../../assets/wechat-black-logo.png'
import { useFormik } from 'formik'
import { sendMail } from './Contact.service';


const Contact = () => {

  const { language } = useContext(LanguageContext)
  const [send, setSend] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    setIsShown(current => !current);
  };


  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = language === LanguageTypes.SPANISH ? 'Campo Obligatorio' : ' Obligarotio en chino';
    } else if (values.name.length > 15) {
      errors.name = language === LanguageTypes.SPANISH ? 'Ingrese un nombre con menos de 15 caracteres' : ' Ingrese un nombre con menos de 15 caracteres en chino';
    }

    if (!values.email) {
      errors.email = language === LanguageTypes.SPANISH ? 'Campo Obligatorio' : ' Obligarotio en chino';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = language === LanguageTypes.SPANISH ? 'Mail Invalido' : ' Mail Invalido en chino';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      text: ''
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true)
      sendMail(values).then(() => setSend(true))

      setTimeout(() => {
        setSend(false)
        setLoading(false)
        resetForm({ values: '' })
      }, 5000)
    },
  });


  function url() {
    window.open('https://www.linkedin.com/company/negocios-con-china/', '_blank');
    
  }

  return (
    <>
      <div className='contact-section'>
        <div className="Contact" id='contact'>
          <div className='contact-button-wrapper'>
            <button className='show-contact-button' onClick={handleClick}> {language === LanguageTypes.SPANISH ? 'Get in touch' : '联系我们'} </button>
          </div>
          {isShown && (
            <div className='show-contact-component'>
              <div className='contact-component-style'></div>
              <div className='component-contact'>
                <button onClick={handleClick} className="close">X</button>
                {!send ?
                  <form onSubmit={formik.handleSubmit} className='form'>
                    <div className='inputs'>
                      {language === LanguageTypes.SPANISH ?
                        <>
                          <input
                            id={ContactSpanish[0].name}
                            name={ContactSpanish[0].name}
                            placeholder={ContactSpanish[0].text}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className='formError'>{formik.errors.name}</div>
                          ) : null}
                          <input
                            id={ContactSpanish[1].name}
                            name={ContactSpanish[1].name}
                            placeholder={ContactSpanish[1].text}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className='formError'>{formik.errors.email}</div>
                          ) : null}
                          <textarea
                            id={ContactSpanish[2].name}
                            name={ContactSpanish[2].name}
                            placeholder={ContactSpanish[2].text}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.text}
                          />
                        </>
                        :
                        <>
                          <input
                            id={ContactChinese[0].name}
                            name={ContactChinese[0].name}
                            placeholder={ContactChinese[0].text}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className='formError'>{formik.errors.name}</div>
                          ) : null}
                          <input
                            id={ContactChinese[1].name}
                            name={ContactChinese[1].name}
                            placeholder={ContactChinese[1].text}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className='formError'>{formik.errors.email}</div>
                          ) : null}
                          <textarea
                            id={ContactChinese[2].name}
                            name={ContactChinese[2].name}
                            placeholder={ContactChinese[2].text}
                            onChange={formik.handleChange}
                            value={formik.values.text}
                          />
                        </>
                      }
                    </div>
                    <div className='button-form'>
                      {loading ?
                        <button type='submit' disabled>
                          {language === LanguageTypes.SPANISH ? 'Enviar' : '发送'}
                        </button>
                        :
                        <button type='submit' >
                          {language === LanguageTypes.SPANISH ? 'Enviar' : '发送'}
                        </button>
                      }
                    </div>
                  </form>
                  :
                  <div className='form-submit'>
                    <div className="v-line">
                    </div>
                    <div className='form-message'>
                      {language === LanguageTypes.SPANISH ?
                        <p> Se envio correctamente el form  </p>
                        :
                        <p> 表格已正确发送  </p>
                      }
                    </div>
                  </div>
                }
                <div className='social-media'>
                  <div className='social-info'>
                    <div className='social-items'>
                      <div className='social-logo'>
                        <img src={LogoUbication} alt='logo-ubication' />
                      </div>
                      <div className='social-items-p'>
                        <p> Cordoba, Argentina</p>
                        <p> Buenos Aires, Argentina</p>
                      </div>
                    </div>
                    <div className='social-items'>
                      <div className='social-logo' id='gmail'>
                        <img src={LogoGmail} alt='logo-gmail' />
                      </div>
                      <div className='social-items-p'>
                        <p>negocios.china.argentina@gmail.com</p>
                      </div>
                    </div>
                    <div className='social-items'>
                      <div className='social-logo' id='phone'>
                        <img src={LogoPhone} alt='logo-phone' />
                      </div>
                      <div className='social-items-p'>
                        <p>+54 9 11 2163-0250</p>
                      </div>
                    </div>
                    <div className='social-items'>
                      <div className='social-logo'>
                        <img src={LogoWechat} alt='logo-Wechat' id='wechat' />
                      </div>
                      <div className='social-items-p' id='wechat_p' >
                        <p>maticailio</p>
                      </div>
                    </div>
                  </div>
                  <div className="h-line">
                  </div>
                  <div className='social-apps'>
                    <div className='social-app-p'>
                      <p>Seguinos !</p>

                    </div>
                    <div className='social-app-logo '>
                      <a href='https://www.instagram.com/negocios.china' target="_blank" id='ig-black' rel="noreferrer" ><img src={LogoInstagram} alt='instagram' /></a>
                      <a href='https://www.instagram.com/negocios.china' target="_blank" id='ig-white' rel="noreferrer" ><img src={LogoInstagramWhite} alt='instagram' /></a>

                      <a href='https://www.linkedin.com/company/negocios-con-china/' target="_blank" rel="noreferrer"><img src={LogoLinkedin} id='li-black' alt='linkedin' /></a>
                      <img src={LogoLinkedinWhite} id='li-white' onClick={url} alt='linkedin' />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <img className="background-white-img" src={require('../../assets/white-bg.png')} alt=""></img>
      </div>
    </>
  );
}

export default Contact; 