import { useContext } from 'react';
import LanguageContext from '../../Context/Language';
import { LanguageTypes } from '../Types/LanguageTypes';
import './Services.css'
import { ServicesChinese, ServicesSpanish } from './ServicesText';
const Services = () => {
    const { language } = useContext(LanguageContext)

    return (
        <>
            <div className="Services" id='service'>
                <h1> {language === LanguageTypes.SPANISH ? 'Servicios' : '我们业务领域'}</h1>
                <div className="services-targets">
                    {language === LanguageTypes.SPANISH ? ServicesSpanish.map(label =>
                        <div className="services-card-info">
                            <img src={require("../../assets/" + label.img)} alt={label.alt} width={label.width} height={label.height}></img>
                            <hr></hr>
                            <p className="p-info-card">{label.text}</p>
                            <p>{label.p}</p>
                        </div>
                    ) : ServicesChinese.map(label =>
                        <div className="services-card-info">
                            <img src={require("../../assets/" + label.img)} alt={label.alt} width={label.width} height={label.height}></img>
                            <hr></hr>
                            <p className="p-info-card">{label.text}</p>
                            <p>{label.p}</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Services; 