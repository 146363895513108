import { useContext } from "react";
import LanguageContext from "../../Context/Language";
import ButtonLanguaje from "../ButtonLanguage/ButtonLanguage";
import { LanguageTypes } from "../Types/LanguageTypes";
import './NavBar.css'
import { NavBarChinese, NavBarSpanish } from "./NavBarText";
import { useEffect, useState } from "react";
import ToggleLanguage from "../ToggleLanguaje/ToggleLanguage";
import BurgerMenu from "../BurgerMenu/BurguerMenu";

const NavBar = () => {

    const { language } = useContext(LanguageContext)

    const [isVisible, setIsVisible] = useState(false);
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [burguerOpen, setBurgueOpen] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, [])

    useEffect(() => {
        setWidth(window.innerWidth)
    })
    const handleNavigation = (link) => {
        setBurgueOpen(false)
        document.getElementById(link).scrollIntoView({ behavior: "smooth" })
    }

    const listenToScroll = () => {
        let heightToHideFrom = 200;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll > heightToHideFrom) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const toggleBurger = () => {
        setBurgueOpen(!burguerOpen)
    }



    return (
        <>
            {
                burguerOpen ?
                    <div className="nav-bar-responsive">
                        <div className="burger" onClick={toggleBurger}>
                            <BurgerMenu />
                        </div>
                        <nav className="nav-responsive">
                            <div className="nav-info-responsive">
                                {/* {isVisible && <img className="nav-logo" src={require('../../assets/logo-white.png')} alt='logo' />} */}
                                {language === LanguageTypes.SPANISH ?
                                    NavBarSpanish.map(label =>
                                        <div key={label.text} className="nav-links nav-links-responsive">
                                            <button className="button-nav" onClick={() => handleNavigation(label.link)}>{label.text}</button>
                                        </div>
                                    )
                                    :
                                    NavBarChinese.map(label =>
                                        <div className="nav-links nav-links-responsive">
                                            <button className="button-nav" onClick={() => handleNavigation(label.link)}>{label.text}</button>
                                        </div>
                                    )
                                }
                                {/* <div className="toggle-responsive">
                                    <ToggleLanguage responsive={'right: 50'} />
                                </div> */}
                            </div>
                        </nav>
                    </div>
                    :
                    <>
                        <nav className="nav ">
                            <div className="nav-info">
                                {isVisible && <img className="nav-logo" src={require('../../assets/logo-white.png')} alt='logo' />}
                                {language === LanguageTypes.SPANISH ?
                                    NavBarSpanish.map(label =>
                                        <div key={label.text} className="nav-links">
                                            <button className="button-nav" onClick={() => handleNavigation(label.link)}>{label.text}</button>
                                        </div>
                                    )
                                    :
                                    NavBarChinese.map(label =>
                                        <div className="nav-links">
                                            <button className="button-nav" onClick={() => handleNavigation(label.link)}>{label.text}</button>
                                        </div>
                                    )
                                }
                                <div className="nav-links">
                                    {/* <ButtonLanguaje /> */}
                                    {width > 768 && <ToggleLanguage responsive={'right: 8rem'} />}
                                </div>
                            </div>
                        </nav>
                        <nav className="nav-responsive-line">
                            <img className="nav-logo" src={require('../../assets/logo-white.png')} alt='logo' />
                            <div className="nav-responsive-links">
                                {/* <ButtonLanguaje /> */}
                                <div className=" toggle-responsive toggle">
                                    <ToggleLanguage />
                                </div>
                                <div className="burger-responsive" onClick={toggleBurger}>
                                    <BurgerMenu />
                                </div>
                            </div>
                        </nav>
                    </>
            }

        </>
    );
}

export default NavBar; 