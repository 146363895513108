import React, { useState } from 'react';
import './WeChat.css';
import logoWeChat from '../../assets/WeChat_logo_icon.png';
import qr from '../../assets/QR_WECHAT.png'




const WeChat = () => {

    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }
    return (
        <>
            <div className="wechat-logo">
                <img src={logoWeChat} onClick={togglePopup} alt='ID Matías' />
                {isOpen &&
                    <div className="popup">
                        {/* <h2>WeChat</h2> */}
                        <img id='qr_wechat' src={qr} alt='ID Matías' />
                        <button onClick={togglePopup}>X</button>
                    </div>
                }
            </div>
            <div>

            </div>
        </>
    )
}

export default WeChat;