export const AboutUsSpanish = [
    {
        text: 'Equidad (义), Armonía  (和) y Puente (桥) son los tres caracteres que elegimos para nombrar a nuestra empresa. Estos tres conceptos resumen la base conceptual de nuestra misión empresarial y el marco para trabajar las relaciones con China.',
        title: 'VISIÓN'
    },
    {
        text: 'Es el crecimiento del comercio exterior entre ambos países, bajo la premisa de que, para hacer negocios en el país asiático, se requieren conocimientos específicos y particulares,  diferentes a los del resto de los países del mundo.',
        title: 'MISIÓN'

    },
    {
        text: 'El sistema político, el idioma, la economía, los parámetros culturales y las características geográficas de China exigen una mirada diferencial para que los negocios se desarrollen y sean exitosos. Ese conocimiento es nuestro valor agregado en el proceso comercial.',
        title: 'VALORES'

    },

]

export const AboutUsChinese = [
    {
        text: '我们将”信义”、”和谐”、”桥梁” 这三个概念各取一字，为公司命名。这三个概念精准概括了我们的商业使命和与中国友好合作的信条。',
        title: '愿景'

    },
    {
        text: '跟世界上其他国家和地区相比，与亚洲国家做生意和投资需要特殊的知识和信息。我们的使命正是通过提供专业的信息和服务，促进中阿两国对外贸易的增长。',
        title: '使命'

    },
    {
        text: '对中国特殊的政治制度、语言、经济、文化和地理特征的深入了解，是我们业务流程中的附加值，引导投资者用不同的视角对待中国市场，促进企业的发展和成功。',
        title: '价值观'

    },
]
