export const ServicesSpanish = [
    {
        text: 'Servicios de Comercio Exterior',
        p: 'Gestionamos la logística de tu operación comercial entre Argentina y China.',
        img: 'icons8-airport-50.png',
        width: "40",
        height: "40",
        alt: 'magnifying_glass'
    },
    {
        text: 'Inteligencia Comercial',
        p: 'Segmentamos la demanda china para encontrar mercados potenciales para tu producto. ',
        img: 'icons8-luz-encendida-50.png',
        width: "43",
        height: "43",
        alt: 'gear'
    },
    {
        text: 'Networking',
        p: 'Conectamos tu proyecto con partners chinos.',
        img: 'hand-shaking1.png',
        width: "35",
        height: "35",
        alt: 'hand_shake'
    },
    {
        text: 'Consultoría',
        p: 'Asesoramos a tu empresa para iniciar una operatoria de comercio exterior en China.',
        img: 'icons8-meeting-64.png',
        width: "45",
        height: "45",
        alt: 'target'
    },
    {
        text: 'Capacitación',
        p: 'Preparamos al personal acerca de las relaciones comerciales Argentina-China, estudios de mercado, economía china y marco normativo.',
        img: 'icons8-class-64.png',
        width: "47",
        height: "47",
        alt: 'tools'
    },
    {
        text: 'Inversiones',
        p: 'Asesoramos en la búsqueda de inversiones para exportar a China. ',
        img: 'icons8-investor-50.png',
        width: "55",
        height: "55",
        alt: 'trophy'
    }
]

export const ServicesChinese = [
    {
        text: '外贸服务',
        p: '我们为您管理阿根廷和中国之间商业运营的物流。',
        img: 'icons8-airport-50.png',
        width: "45",
        height: "45",
        alt: 'magnifying_glass'
    },
    {
        text: '商业智库',
        p: '我们准确分析需求，为您的产品寻找潜在市场。',
        img: 'icons8-luz-encendida-50.png',
        width: "35",
        height: "35",
        alt: 'gear'
    },
    {
        text: '互联合作',
        p: '我们将您的项目与阿根廷合作伙伴对接。',
        img: 'hand-shaking1.png',
        width: "35",
        height: "35",
        alt: 'hand_shake'
    },
    {
        text: '商业咨询',
        p: '我们为您的公司在中国开展外贸业务提供战略和管理建议。',
        img: 'icons8-meeting-64.png',
        width: "45",
        height: "45",
        alt: 'target'
    },
    {
        text: '培训课程',
        p: '我们提供的专业培训课程包括：阿根廷与中国贸易关系、市场研究、中国经济和监管框架。',
        img: 'icons8-class-64.png',
        width: "45",
        height: "45",
        alt: 'tools'
    },
    {
        text: '投资服务',
        p: '我们为您提供可行的项目和投资机会',
        img: 'icons8-investor-50.png',
        width: "45",
        height: "45",
        alt: 'trophy'
    }
]
