import { useContext } from 'react';
import LanguageContext from '../../Context/Language';
import { LanguageTypes } from '../Types/LanguageTypes';
import './Footer.css'
import { FooterChinese, FooterSpanish } from './FooterText';
const Home = () => {

    const { language } = useContext(LanguageContext)
    const handleNavigation = (link) => {
        document.getElementById(link).scrollIntoView({ behavior: "smooth" })
    }

    return (
        <>
            <footer class="footer">
                <div class="main-footer">
                    <div class="footer-left">
                        <h2>义和桥( Yì hé qiáo ）</h2>
                        <p id="p-footer">{language === LanguageTypes.SPANISH ? FooterSpanish.text : FooterChinese.text}</p>
                    </div>
                    <ul class="footer-right">
                        <li>
                            <h2 class="footer-titles" id="h2_footer_resources">RECURSOS</h2>
                            <ul class="footer-box">
                                <li><button id="a_dw" onClick={() => handleNavigation('service')}>Optimizacion</button></li>
                                <li><button id="a_uxui" onClick={() => handleNavigation('service')} >Desarrollo</button></li>
                                <li><button id="a_te" onClick={() => handleNavigation('service')}>Comunicacion</button></li>
                            </ul>
                        </li>
                        <li>
                            <h2 class="footer-titles" id="h2_footer_us">NOSOTROS</h2>
                            <ul class="footer-box">
                                <li>
                                    <button id="a_qs" onClick={() => handleNavigation('aboutus')}>¿Quienes Somos?</button></li>
                                <li><button id="a_tn" onClick={() => handleNavigation('contact')}>Trabaja con nosotros</button></li>
                            </ul>
                        </li>
                        <li>
                            <h2 class="footer-titles" id="h2_footer_contact">CONTACTO</h2>
                            <ul class="footer-box">
                                <li><p>negocios.china.argentina@gmail.com</p></li>
                                <li><p >+54 9 11 2163-0250</p></li>
                                <li><p>@negocios.china</p></li>
                                <li><p>weChat User :  maticailio</p></li>

                            </ul>
                        </li>
                    </ul>
                    <div class="footer-bottom">
                        <p id="rights">All rights reserved by &copy;YiHeQiao 2022</p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Home; 