import { useContext } from "react";
import LanguageContext from "../../Context/Language";
import { LanguageTypes } from "../Types/LanguageTypes";
import './Home.css'
import { HomeChinese, HomeSpanish } from "./HomeText";
const Home = () => {
    const { language } = useContext(LanguageContext)

    const handleNavigation = (link) => {
        document.getElementById(link).scrollIntoView({ behavior: "smooth" })
    }
    return (
        <>
            <div className="Home" id='home'>
                <div className="home-background">
                    <img className="home-logo" src={require('../../assets/logo-white.png')} alt='logo' />
                    <div className="home-title">
                        <h2>{language === LanguageTypes.SPANISH ? HomeSpanish.h2 : HomeChinese.h2}</h2>
                    </div>
                    <button className="home-button" onClick={() => handleNavigation('service')} >{language === LanguageTypes.SPANISH ? HomeSpanish.button : HomeChinese.button}</button>
                </div>
                <div className="home-description">
                    <h2>{language === LanguageTypes.SPANISH ? HomeSpanish.text : HomeChinese.text}</h2>
                </div>
            </div>
        </>
    );
}

export default Home; 