

// export interface LanguajeTypes {
//     SPANISH : 'es',
//     CHINESE : 'cn'
// }

export const LanguageTypes = {
    SPANISH : 'es',
    CHINESE : 'cn'  
}