export const FooterSpanish =
{
    text: 'Equidad (义), Armonía  (和) y Puente (桥) son los tres caracteres que elegimos para nombrar a nuestra empresa. Estos tres conceptos resumen el  marco en el que buscamos potenciar las relaciones de nuestro país con China y la base conceptual de nuestra misión empresarial.',
}


export const FooterChinese =
{
    text: '我们将”信义”、”和谐”、”桥梁” 这三个概念各取一字，为公司命名。这三个概念精准概括了我们的商业使命和与中国友好合作的信条。',

}
