import { useContext } from 'react'
import LanguageContext from '../../Context/Language'
import { LanguageTypes } from '../Types/LanguageTypes'
import './AboutUs.css'
import { AboutUsChinese, AboutUsSpanish } from './AboutUsText'
import CV_YIHEQIAO_Matias_Lioni from "./CV/CV YIHEQIAO_ML_2023.pdf"
import CV_YIHEQIAO_SN from "./CV/CV YIHEQIAO_SN_2023.pdf"

const AboutUs = () => {
    const { language } = useContext(LanguageContext)
    return (
        <>
            <div className="AboutUs" id="aboutus">
                <h2 className="about-us-tittle">{language === LanguageTypes.SPANISH ? 'NOSOTROS' : '关于我们'} </h2>
                <div className="about_container">
                    <div className="video_about">
                        {/* <iframe src="https://www.youtube.com/embed/7dyX34T7WoU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    </div>
                    <div className="p_about">

                        {language === LanguageTypes.SPANISH ?
                            AboutUsSpanish.map(label =>
                                <div className="p_about_card">
                                    <h3>{label.title}</h3>
                                    <p>{label.text}</p>
                                </div>
                            )
                            :
                            AboutUsChinese.map(label =>
                                <div className="p_about_card">
                                    <h3>{label.title}</h3>
                                    <p>{label.text}</p>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="split"></div>
                <h4 className="about-us-profiles-tittle">{language === LanguageTypes.SPANISH ? 'Socios fundadores' : '合伙创始人'}</h4>
                <div className="profiles-container">
                    <div className="profile">
                        <div className="photo-cv-container">
                            <img className="cv-photo" id='matias_cv' src={require('../../assets/MatiasLioni.jpg')} alt='foto1' />
                        </div>
                        <h5>{language === LanguageTypes.SPANISH ? 'Mg. Matías Lioni' : '马帝开 (Matías Lioni) 经济师 - 简历'}</h5>
                        <div className="btn_container">
                            <a href={CV_YIHEQIAO_Matias_Lioni} target="_blank" rel="noreferrer" title="cv Matias Lioni"><button className="cv_btn">cv</button></a>
                            <a href="https://ar.linkedin.com/in/matiasjavierlioni" target="_blank" rel="noopener noreferrer"><button className="ln_btn">in</button></a>
                        </div>
                    </div>
                    <div className="profile">
                        <div className="photo-cv-container">
                            <img className="cv-photo" src={require('../../assets/SantiagoNotar.jpg')} alt='foto2' />
                        </div>
                        <h5>{language === LanguageTypes.SPANISH ? 'Ab. Esp. Santiago Notarfrancesco' : 'Santiago Notarfrancesco 律师- 简历'}</h5>
                        <div className="btn_container">
                            <a href={CV_YIHEQIAO_SN} target="_blank" rel="noreferrer" title="cv Santiago Notarfrancesco"><button className="cv_btn">cv</button></a>
                            <a href="https://ar.linkedin.com/in/santinotar" target="_blank" rel="noopener noreferrer"><button className="ln_btn">in</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUs; 