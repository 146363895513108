export const HomeSpanish =
{
    h2: 'Impulsamos tu negocio entre Argentina y China',
    button: '+ INFO',
    text: 'Gestión integral de servicios de comercio exterior',
}

export const HomeChinese =
{
    h2: '推动您在阿根廷和中国之间的业务发展',
    button: '更多信息',
    text: '对外贸易综合服务',
}
