import './App.css';
import AboutUs from './components/AboutUs/AboutUs';
import Contact from './components/Contact/Contact';
import Home from './components/Home/Home';
import NavBar from './components/NavBar/NavBar';
import WeChat from './components/WeChat/WeChat';
import Services from './components/Services/Services';
import Footer from './components/Footer/Footer'
import { LanguageContextProvider } from './Context/Language';

function App() {
  return (
    <>

      <LanguageContextProvider>
      <div className='App'>
        <NavBar />
        <Home />
        <AboutUs />
        <Services/>
        <Contact /> 
        <Footer />
        <WeChat />
      </div>

      </LanguageContextProvider>
    </>
  );
}

export default App;
