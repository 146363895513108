export const NavBarSpanish = [
    {
        text: 'Home',
        link: 'home'
    },
    {
        text: 'Nosotros',
        link: 'aboutus'

    },
    {
        text: 'Servicios',
        link: 'service'

    },
    {
        text: 'Contacto',
        link: 'contact'

    }
]

export const NavBarChinese = [
    {
        text: '首页',
        link: 'home'

    },
    {
        text: '关于我们',
        link: 'aboutus'

    },
    {
        text: '业务领域',
        link: 'service'

    },
    {
        text: '联系方式',
        link: 'contact'

    }
]
