export const ContactSpanish = [
    {
        text : 'Nombre',
        name : 'name',
        placeholder : ' Nombre'
    },
    {
        text : 'Email',
        name : 'email',
        placeholder : ' Email'
    },
    {
        text : 'Texto',
        name : 'text',
        placeholder : ' Texto'
    }
]

export const ContactChinese = [
    {
        text : '姓名',
        name : 'name',
        placeholder : ' 姓名'
    },
    {
        text : '电子邮件',
        name : 'email',
        placeholder : ' 电子邮件'
    },
    {
        text : '文本',
        name : 'text',
        placeholder : ' 文本'
    }
]


